import { graphql, useStaticQuery } from 'gatsby'
import { AuthorModel } from '../models'

export const getGraphAuthors = (): AuthorModel[] => {
  const {
    allAuthors: { nodes: authors },
  } = useStaticQuery(graphql`
    query {
      allAuthors {
        nodes {
          description
          facebook
          fullname
          id
          image
          instagram
          job
          pseudonym
          twitter
          comics {
            id
            role
          }
        }
      }
    }
  `)

  return authors.length > 0 ? authors : []
}
